import { loadingIndex } from "./modules/loadingindex";
import { loadingGoods } from "./modules/loadinggoods";
import { search } from "./modules/search";
import { moreAll } from "./modules/moreall";
import { cart } from "./modules/cart";
import { scrollTop } from "./modules/scrolltop";
import { sendFormCart } from "./modules/sendform";
import { addSuccessText } from './modules/addsuccesstext';
import { swip } from "./modules/swiper";


// это отдельно___________________________
import  { myPromis } from './modules/mypromis';
myPromis();
// ___________________________


swip();
loadingIndex();
loadingGoods();
addSuccessText();
moreAll();
cart();
search();
scrollTop();
sendFormCart();
