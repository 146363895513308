const myPromis = function () {
  // const beer = fetch('https://api.sampleapis.com/beers/ale')
    // const wine = fetch('https://api.sampleapis.com/wines/reds')
    // Promise.all([beer, wine])
    //     .then(data =>  Promise.all(data.map(data => data.json())))
    //     .then(([beer, wine]) => console.log(beer, wine))


    // const makeCoffee = () => {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       resolve('ваш блин кофе готов')
    //     }, 500);
    //   });
    // }

    // const makeToast = () => {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       resolve('ваш тост готов')
    //     }, 2500);
    //   });
    // }



    // const coffeePromise = makeCoffee();
    // const toastPromise = makeToast();
    // Promise.all([coffeePromise, toastPromise])
    // .then(([coffeePromise, toastPromise]) => console.log(coffeePromise, toastPromise))

    // // coffeePromise.then(data => console.log(data))
    // // toastPromise.then(data => console.log(data))

    // const family = [
    //   {member: 'mother', id: 111, coffee: 'Americano',},
    //   {member: 'father', id: 222, coffee: 'Espresso',},
    //   {member: 'son', id: 333, coffee: 'Irish',},
    // ]

    // const getCoffee = (member) => {
    //   const coffee = fetch('https://api.sampleapis.com/coffee/hot');
    //   return coffee
    //     .then(data => data.json())
    //     .then(data => {
    //       console.log(data);
    //       const newCoffee = data.find(dataItem => dataItem.title == member.coffee)

    //       return {
    //         ...member, coffee: newCoffee
    //       }
    //     })
    // }

    // const getFamilyMember = (id) => {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       const member = family.find(fam => fam.id === id);
        
    //       if (member) {
    //         resolve(member);
    //       } else {
    //         reject(Error('нет тут такого члена'));
    //       }
          
    //     }, 2000);
    //   });
    // }

    // // console.log(family);
    // getFamilyMember(444)
    //  .then(data => getCoffee(data))
    //  .then(newMember => console.log(newMember))
    //  .catch(err => console.log(err))
  
    // // const baseURL = 'https://api.sampleapis.com/beers/ale';
    // // fetch(baseURL)
    // //   .then(res => res.json())
    // //   .then(dd => {
    // //     console.log(dd);
    // //   })
    // //   .catch(err => {
    // //     console.log('ошибочка', err);
    // //   })
}

export { myPromis };