import { addSuccessText } from './addsuccesstext';
import { loadingGoods } from './loadinggoods';
import { loadingIndex } from './loadingindex';

const cart = function () {
  const cartBtnOpen = document.querySelector('.button-cart');
  const cartModal = document.querySelector('#modal-cart');
  const cartBtnClose = cartModal.querySelector('.modal-close');
  const cartTable = cartModal.querySelector('.cart-table__goods');
  const cartCount =  document.querySelector('.cart-count');
  const listsContainer = document.querySelectorAll('.short-goods, .long-goods-list');
  const totalSumm = document.querySelector('.card-table__total');
  const titleCart = document.querySelector('.modal-title');

  titleCart.textContent = 'Cart';
  cartCount.textContent = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : [];

  const openCartModal = () => {
    cartModal.style.display = 'flex';
    window.addEventListener('keydown', closeCartModal);
    cartBtnClose.addEventListener('click', closeCartModal);
    cartModal.addEventListener('click', closeCartModal);

    const cartArray = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
    renderCart(cartArray)
  }
  
  const closeCartModal = (e) => {
    if (e.target.classList.contains('overlay') || e.key === 'Escape'|| e.target.classList.contains('modal-close')) {
      cartModal.style.display = 'none';
      window.removeEventListener('keydown', closeCartModal);
      cartBtnClose.removeEventListener('click', closeCartModal);
      cartModal.removeEventListener('click', closeCartModal);
      titleCart.textContent = 'Cart';
    }
  }

  cartBtnOpen.addEventListener('click', openCartModal);

  function plusCount (id) {
    const array = JSON.parse(localStorage.getItem('cart'));
    array.map(item => item.id === id ? item.count++ : item);
    localStorage.setItem('cart', JSON.stringify(array));
    renderCart(array);
    totalSum();
  }

  function minusCount (id) {
    const array = JSON.parse(localStorage.getItem('cart'));
    array.map(item => item.id === id && item.count > 1 ? item.count-- : item);
    localStorage.setItem('cart', JSON.stringify(array));
    renderCart(array);
    totalSum();
  }

  function deletCartItem (id) {
    const array = JSON.parse(localStorage.getItem('cart')) ||  [];
    const newArray = array.filter((item) => item.id !== id)
    localStorage.setItem('cart', JSON.stringify(newArray));
    renderCart(newArray);
    totalSum();
    loadingGoods();
    loadingIndex();
    addSuccessText();
    cartCount.textContent = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : [];
    if (localStorage.getItem('cart') && JSON.parse(localStorage.getItem('cart')).length == 0) {
      cartCount.textContent = '';
    }
  }

  function totalSum () {
    const array = JSON.parse(localStorage.getItem('cart'));
    if (array.some((item) => item.count > 0)) {
      const totalSum = array.map((item) => +item.price * +item.count).reduce((total, item) => total + item);
      totalSumm.textContent = `${totalSum}$`;
    } else {
      totalSumm.textContent = '';
    }
  }

  // const resetCart = () => {
  //   cartTable.innerHTML = '';
  //   totalSumm.textContent = '';
  //   localStorage.removeItem('cart');
  // }

  function renderCart (array) {
    cartTable.innerHTML = "";

    array.forEach(element => {
      const {name, price, count, id} = element;
      const tr = document.createElement('tr');
      tr.innerHTML = `
        <th>${name}</th>
        <th>${price}$</th>
        <td><button class="cart-btn-minus" "="">-</button></td>
        <th>${count}</th>
        <td><button class="cart-btn-plus" "="">+</button></td>
        <th>${count > 1 ? +(price * count) : +price}</th>
        <td><button class="cart-btn-delete" "="">x</button></td>
      `
      cartTable.appendChild(tr);
      totalSum();

      tr.addEventListener('click', function (e) {
        if (e.target.classList.contains('cart-btn-delete')) {
          deletCartItem(id);
        } else if (e.target.classList.contains('cart-btn-minus')) {
          minusCount(id);
        } else if (e.target.classList.contains('cart-btn-plus')) {
          plusCount(id);
        }
      });
    });
  }

  const addToCart = function (id) {
    const array = JSON.parse(localStorage.getItem('newdata'));
    const clickedCart = array.find(item => item.id === id);
    const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

    if (cart.some(item => item.id === clickedCart.id)) {
      cart.map(item => {
        if (item.id === clickedCart.id) {
          item.count++;
        }
        return item;
      })
      
      localStorage.setItem('cart', JSON.stringify(cart));
    } else {
      clickedCart.count = 1;
      clickedCart.offer = true;
      cart.push(clickedCart);
    }

    localStorage.setItem('cart', JSON.stringify(cart));
    addSuccessText();
    cartCount.textContent = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : [];
  }

  if (listsContainer) {
    listsContainer.forEach(container => {
      container.addEventListener('click', function (e) {
        e.preventDefault();
        if(e.target.closest('.add-to-cart')) {
          const cartId = e.target.closest('.add-to-cart').dataset.id;
          addToCart(cartId)
        } else {
          return;
        }
      });
    });
  }
};

export { cart };
