import { categoryText } from "./category";

export function activeMenu () {
  const links = document.querySelectorAll('.navigation-link');
  // const nav = document.querySelector('nav');
  
  function onRemoveActiveLink () {
    links.forEach(link => {
      if (link.classList.contains('navigation-link--active')) {
        link.classList.remove('navigation-link--active');
      }
    });
  }

  links.forEach(link => {
    const text = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : '';
    categoryText(text);
    
    if (link.textContent === text) {
      link.classList.add('navigation-link--active');
    }
  });

  links.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      onRemoveActiveLink();
      link.classList.add('navigation-link--active');
    });
  });
}
