import { getData } from './getdata';
import { renderData } from './renderdata';

const search = function () {
  const searchBtn =  document.querySelector('.btn-outline-secondary');
  const searchInput =  document.querySelector('.search-block > input');
  const goodsCantainer =  document.querySelector('.long-goods-list');
  const shortList = document.querySelector('.short-goods');

  const onSearch = function (evt, value) {
    getData().then((data) => {
      const newData = data.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

      if (window.location.pathname === "/index.html") {
        renderData(newData, shortList);
      } else {
        renderData(newData, goodsCantainer);
      }
    });

    if (!(evt.target.matches('button') || evt.target.matches('img'))) {
      return;
    }
  };

  if (searchBtn && searchInput) {
    searchBtn.addEventListener('click', (evt) => onSearch(evt, searchInput.value));
  }
}

export {search};
