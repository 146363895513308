export function addSuccessText () {
  const allCards =  document.querySelectorAll('.goods-card');
  const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
  const arraySucces = cart.map((item) => item.id);

  allCards.forEach(element => {
    arraySucces.forEach(item => {
      if(element.dataset.id == item) {
        for (let i = 0; i < element.children.length; i++) {
          if (element.children[i].classList.contains('goods-ok')) {
            element.children[i].classList.add('goods-ok--block');
          } 
        }
      }
    });
  });
}
