export function scrollTop () {
  const links = document.querySelectorAll('.top-link');

  // const getToAnchor = function (event, anchor) {
  //   event.preventDefault();
  //   console.log();
  //     let blockID = anchor.getAttribute('href').substr(1);
  //     document.getElementById(blockID).scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //   });
  // };

  // for (let i = 0; i < links.length; i++) {
  //   (function () {
  //     let anchor = links[i];
  //     anchor.addEventListener('click', function () {
  //       getToAnchor(event, anchor);
  //     });
  //   })();
  // }
  

  links.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        block: 'start',
        behavior: 'smooth'
      });
    });
  });
}