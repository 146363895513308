import { getData } from './getdata';
import { renderData } from './renderdata';
import { compareRandom } from './comparerandom';

const loadingIndex = function () {
  const shortList = document.querySelector('.short-goods');

  if (shortList) {
    getData().then((data) => {
      const newData = data.filter((item) => item.label.toLowerCase() === "new");
      const sliceArray = newData.sort(compareRandom).slice(0, 4);
            
      renderData(sliceArray, shortList);
      localStorage.setItem('newdata', JSON.stringify(sliceArray));
    });
  }
}

export {loadingIndex};