import {getData} from './getdata';
import { renderData } from './renderdata';

const moreAll = () => {
  const more =  document.querySelector('.more');
  const shortList = document.querySelector('.short-goods');

  if (more) {
    more.addEventListener('click', function (e) {  
      e.preventDefault();    
      getData().then((data) => {    
        renderData(data, shortList);
        localStorage.setItem('newdata', JSON.stringify(data));
      });
    });
  }
}

export { moreAll };
