// import { addSuccessText } from './addsuccesstext';
import { removeSuccessText } from './removesuccesstext';

export function sendFormCart () {
  const modalForm = document.querySelector('.modal-form');
  const formName = modalForm.querySelector('input[name=nameCustomer]');
  const formPhone = modalForm.querySelector('input[name=phoneCustomer]');
  const modal = document.querySelector('.modal');
  const cartCount =  document.querySelector('.cart-count');

  const URL = 'https://jsonplaceholder.typicode.com/posts';
  
  const cartModal = document.querySelector('#modal-cart');
  const cartTable = cartModal.querySelector('.cart-table__goods');
  const totalSumm = document.querySelector('.card-table__total');
  const titleCart = document.querySelector('.modal-title');

  const resetCart = () => {
    cartTable.innerHTML = '';
    totalSumm.textContent = '';
    cartCount.textContent = '';
    localStorage.removeItem('cart');
    modalForm.reset();
    modal.classList.add('modal-send-js');
   
    titleCart.textContent = `Заказ отправлен, спасибо ${formName.value}!`;
    setTimeout(() => {
      titleCart.textContent = 'Cart';
      modal.classList.remove('modal-send-js');
    }, 3000);
    removeSuccessText();
    if (window.location.pathname !== "/index.html") {
      window.location.href === "/index.html"
    }

    setTimeout(() => {
      cartModal.style.display = 'none';
    }, 2500);
  }

  function sendForm () {
    const cartArray = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
    fetch(URL, {
      method: 'POST',
      body: JSON.stringify({
        cart: cartArray,
        name: formName.value,
        phone: formPhone.value
      })
    }).catch(err => titleCart.textContent = `${err}!`)
  }

  modalForm.addEventListener('submit', function (e) {
    e.preventDefault();
    console.log('submit');
    sendForm();
    resetCart();
  });
}