import { addSuccessText } from './addsuccesstext';

const renderData = function (data, container) {
  if (data && container) {
    container.innerHTML = '';
  
    data.forEach(element => {
      const {category, description, gender, id, img, label, name, offer, price} = element;

      const goodsBlock = document.createElement('div');
      goodsBlock.className = 'col-lg-3 col-sm-6';
      goodsBlock.innerHTML = `
        <div class="goods-card" data-id="${id}">
          <span class="label ${label ? null : 'd-none'}">${label}</span>
          <img src="db/${img}" alt="image: ${name}" class="goods-image">
          <h3 class="goods-title">${name}</h3>
          <p class="goods-description">${description}</p>
          <p class="goods-ok">товар добавлен в корзину</p>
          <button class="button goods-card-btn add-to-cart" data-id="${id}">
            <span class="button-price">$${price}</span>
          </button>
        </div>
      `
      container.appendChild(goodsBlock)
    });

    addSuccessText();
  }
}

export { renderData };
