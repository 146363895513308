export function removeSuccessText () {
  const allCards =  document.querySelectorAll('.goods-card');
  if (allCards) {
    allCards.forEach(element => {
      for (let i = 0; i < element.children.length; i++) {
        if (element.children[i].classList.contains('goods-ok')) {
          element.children[i].classList.remove('goods-ok--block');
        }
      }
    });
  }
}
