import { getData } from './getdata';
import { renderData } from './renderdata';
import { addSuccessText } from './addsuccesstext';
import { activeMenu } from "./activemenu";
import { categoryText } from './category';

const loadingGoods = function () {

  let dataLocal = JSON.parse(localStorage.getItem('newdata')) || [];
  const links = document.querySelectorAll('.navigation-link');
  const goodsCantainer =  document.querySelector('.long-goods-list');

  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      const linkValue = link.textContent;
      const category = link.dataset.field;
      localStorage.setItem('menu', JSON.stringify(linkValue));

      getData().then((data) => {
        const newData = category ? data.filter((item) => item[category] === linkValue) : data;
        localStorage.setItem('newdata', JSON.stringify(newData));
        if (window.location.pathname !== "/goods.html") {
          window.location.href = "/goods.html";
          activeMenu();
        } else {
          renderData(newData, goodsCantainer);
          activeMenu();
        }
      });
      addSuccessText();
    });
  });
  

  if (dataLocal && window.location.pathname === "/goods.html") {
    renderData(dataLocal, goodsCantainer);
    activeMenu();
  } 
}

export { loadingGoods };
